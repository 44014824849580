import React from 'react'
import { graphql, useStaticQuery, Link} from "gatsby"

import styles from './emergency-services.module.css'
import ContactInfo from '../banner/contact-info'

import Bullet from '../../images/icon/bullet.svg'

const EmergencyServices = () => {
	const query = useStaticQuery(graphql`
	query {
		allStrapiHomeBannerBasicContentManager {
		    edges {
		      node {
		        emergancy_service_call_name
		        emergancy_service_call_icon {
		          publicURL
		        }
		        phone_no
		        service_title
		        title
		        description
		        services {
		          name
		          icon {
		            publicURL
		          }
		        }
		      }
		    }
		}
	  allStrapiEmergencyServicesContentManager {
	    edges {
	      node {
	      	main_title
	      	main_description
	        feature_image {
	          publicURL
	        }
	        id
	        title
	        sub_title
	        short_description
	        services {
	          id
	          name
	          description
	          icon {
	            publicURL
	          }
	        }
	      }
	    }
	  }
	  }
	`)

	let p = [];
	query.allStrapiHomeBannerBasicContentManager.edges.forEach(({node})=>{
		p.push(node)
	})
	p = p[0];


	let emergencyContent = [];
	query.allStrapiEmergencyServicesContentManager.edges.forEach(({node})=>{
		emergencyContent.push(node)
	})
	emergencyContent = emergencyContent[0];
	return (
		<>
			<div className={styles.banner}>
				<div className={styles.bannerLeft}>
					<div>
						<h2>{emergencyContent.main_title}</h2>
						<p>{emergencyContent.main_description}</p>
						<ContactInfo styles={styles} p={p} />
					</div>
				</div>
				<div className={styles.bannerRight}>
					<img src={emergencyContent.feature_image.publicURL} />
				</div>
			</div>

			<div className={styles.emergencyCases}>
				<div className={styles.wrapper}>
					<div className={styles.emergencyHead}>
						<h3>{emergencyContent.title}</h3>
						<h4>{emergencyContent.sub_title}</h4>
						<p>{emergencyContent.short_description}</p>
					</div>
					<div>
					{
						emergencyContent.services.slice(0,3).map((item, index)=>{
							return(
								<div className={styles.oneRow} key={`service_${index}`}>
									<div className={styles.oneRowImg}><img src={item.icon.publicURL} /></div>
									<div className={styles.oneRowDesc}>
										<div className={styles.rowTitle}>
											<div className={styles.titleImg}><img height="20px" src={Bullet} alt={`sks - bullet`} /></div>
											<div className={styles.titleText}><span>{item.name}</span></div>
										</div>
										<p className={styles.description}>{item.description}</p>
									</div>
								</div>
							)
						})
					}
					</div>
				</div>
			</div>

			<div className={`${styles.emergencyCases} ${styles.emergencyCasesWhite}`}>
				<div className={styles.wrapper}>

					<div>
											{
						emergencyContent.services.slice(3,9).map((item, index)=>{
							return(
								<div className={styles.oneRow} key={`service_${index}`}>
									<div className={styles.oneRowImg}><img src={item.icon.publicURL} /></div>
									<div className={styles.oneRowDesc}>
										<div className={styles.rowTitle}>
											<div className={styles.titleImg}><img height="20px" src={Bullet} alt={`sks - bullet`} /></div>
											<div className={styles.titleText}><span>{item.name}</span></div>
										</div>
										<p className={styles.description}>{item.description}</p>
									</div>
								</div>
							)
						})
					}
					</div>
				</div>
			</div>
		</>
	)
}

export default EmergencyServices