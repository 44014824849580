import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import DetailsHead from "../components/detailshead"
import Emergency from "../components/emergency-services"

const EmergencyServices = () => (
  <Layout>
    <SEO title="Emergency Services | 24/7 Emergency Locksmith Services" />
    <DetailsHead title="Emergency Services"/>
    <Emergency/>
  </Layout>
)

export default EmergencyServices
